<template>
  <div
    class="w-full bg-white grid grid-cols-12 font-serif antialiased text-brand-gray-500 relative"
  >
    <div class="col-span-12 lg:col-span-6 px-4 lg:px-11 py-8">
      <div class="flex flex-row justify-between">
        <img
          class="h-9 w-auto object-contain"
          src="./../assets/logo/logo-wide.png"
          alt="Logo"
          @click="$router.push({ name: 'auth.login' }).catch(() => {})"
        />
        <div class="border border-brand-purple-500 ml-14 py-3 px-5">
          <languageSelector />
        </div>
      </div>

      <div
        class="h-full flex justify-center items-center px-4 md:px-8 py-6 max-w-xl mx-auto"
      >
        <router-view />
      </div>
    </div>

    <div class="hidden lg:block lg:col-span-6">
      <img
        class="max-h-screen h-full w-full object-cover"
        src="/images/auth-image.jpg"
        alt="Authentication"
      />

      <div class="absolute right-0 bottom-16 flex flex-row w-auto">
        <svg
          class="relative"
          width="125"
          height="95"
          viewBox="0 0 125 95"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M125 95H77L125 0V95Z"
            fill="#FFC107"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M125 95H77L125 0V95Z"
            fill="#FFC107"
          />
          <path
            opacity="0.799087"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M58.6239 95H33.2067L80.9516 0H106.298L58.6239 95Z"
            fill="#FFC107"
          />
          <path
            opacity="0.667427"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.6239 95H3.8147e-05L47.6181 0H60.2977L12.6239 95Z"
            fill="#FFC107"
          />
        </svg>

        <div class="bg-brand-yellow-500 text-brand-purple-500 py-6 px-12">
          <h2 class="text-xl md:text-4xl uppercase font-black">
            <n-html :text="$t('auth.my_harmony')" />
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import languageCodes from "@/mixins/languageCodes";
import languageSelector from "../components/language/language-selector.vue";

// TODO: replace locales from data with import from mixin
export default {
  mixins: [languageCodes],
  components: {
    languageSelector
  },
  mounted() {
    console.log(i18n.locale);
  },
  data() {
    return {
      currentLanguage: i18n.locale
    };
  },
  methods: {
    switchLanguage(e) {
      const lang = e.target.value;
      i18n.locale = lang;
      this.currentLanguage = lang;
    }
  }
};
</script>
